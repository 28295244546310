<template>
  <vs-prompt
      @accept="acceptEmployeeBilling"
      title="Mitarbeiter Abrechnung"
      @close="$emit('close')"
      acceptText="Jetzt Mitarbeiter abrechnen"
      cancelText="Abbrechen"
      :active.sync="activePrompt">

    <vs-input name="sum_netto" label="Verwendungszweck" v-model="transfer_notice" class="w-full mb-4" autocomplete="off" />
    <div v-for="(employee, index) in employees">

      <vs-row v-if="employee.detail && (employee.id != 1 || employee.id != 72) && employee.active == 1" class="mb-1">
        <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="6">
          <span>{{ employee.detail.first_name }} {{ employee.detail.last_name }}:</span>
          <div v-if="employee.employee_settings">
            <vx-tooltip text="IBAN oder BIC nicht gefüllt" class="w-1" position="left" v-if="isIBANorBicInvalid(employee)">
              <vs-icon icon="cancel" class="error-icon pointer"></vs-icon>
            </vx-tooltip>
          </div>
        </vs-col>
        <vs-col vs-type="flex" vs-justify="left" vs-align="top" vs-w="6">
          <vs-input name="sum_netto" v-model="employee.sum" class="w-full" data-vv-as="Summe netto" icon="euro_symbol" icon-after="true" autocomplete="off"
                    v-if="employee.employee_settings && employee.employee_settings.iban && employee.employee_settings.bic" />

        </vs-col>
      </vs-row>
    </div>

  </vs-prompt>
</template>

<script>
import ApiService from "../../api";
import moment from 'moment';
import {mapGetters} from "vuex";

export default {
  name: "EmployeeBillingPopup",
  components: {moment},
  props: [
    'active'
  ],
  data() {
    return {
      activePrompt: false,
      transfer_notice: "",
      employees: [],
    }
  },
  computed: {
    ...mapGetters(['userId','workspace', 'userHasPermission']),
  },
  created() {
    moment.locale("de");
    const now = moment();
    var month = now.format('MMMM');
    var year = now.format('YYYY');
    this.transfer_notice = "Gehalt - "+ this.workspace.unique_name + " - "+month + " " + year;
    this.fetchUsers()
  },
  methods: {
    fetchUsers() {
      ApiService.get('users').then(response => {
        if (response.status !== 200) {
          return;
        }

        this.employees = response.data.result

      }).catch(response => {

      })
    },
    activeChange(value) {
      this.activePrompt = value;
    },
    isIBANorBicInvalid(employee) {
if (!employee.employee_settings.iban  == null) {
  console.log(employee)
      }

      return !employee.employee_settings || !employee.employee_settings.iban || !employee.employee_settings.bic
      //  !employee.employee_settings || !employee.employee_settings.iban || !employee.employee_settings.bic
    },
    acceptEmployeeBilling() {

      if(!this.employees || !this.transfer_notice) {
        this.$vs.notify({
          title: 'Error',
          text: "Daten fehlen",
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      }

      let data = {
        "transaction_title": this.transfer_notice,
        "employees": this.employees
      }

      ApiService.post('bank-transaction-transfer/add-employee-list', data).then((response) => {

        this.$vs.loading.close()

        if(response.data.status === 'success') {
          return this.$vs.notify({
            text: 'Das Team wurde erfolgreich angelegt',
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'success'
          });
        }
      }).catch((response) => {

        this.$vs.loading.close()

        this.$vs.notify({
          title: 'Error',
          text: response.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })
      })


      this.$emit('accept');
    }
  },
  watch: {
    active: 'activeChange'
  }
}
</script>

<style lang="scss">
.vs-tooltip {
  z-index: 7000000;
}
.error-icon {
  top: 0px;
  position: relative;
  margin-left: 5px;
  color: #e5260e;
  font-size: 18px;
}
</style>