const PriceHelper = {
    parseCommaFloat(str) {
        if(typeof str != 'string') {
            return str;
        }

        if (!str) {
            return 0;
        }

        return parseFloat(str.replace(/\./g, '').replace(',', '.'));
    },
    floatPointToComma(string, decimal) {
        if (!decimal) {
            decimal = 2;
        }

        return parseFloat(string).toFixed(decimal).toString().replace('.', ',');
    },
    numberWithCommas(x, decimal) {
        var parts = x.toString().split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return parts.join(".");
    },
    formatCurrency(number, defaultValue = null) {
        if(number === undefined || number === null) {
            return defaultValue || '-';
        }
        return number.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
            currencyDisplay: 'symbol',
            useGrouping: true
        });
    },
    taxPrice(price, tax) {
        return parseFloat(price) * (1 + parseFloat(tax) / 100);
    },
    netPrice(price, tax) {
        return parseFloat(price) / (1 + parseFloat(tax) / 100);
    },
    parseComma(str) {
        if(str === undefined || str === null) {
            return 0
        }
        return str.toString().replace(',', '.')
    }
};

export default PriceHelper;
