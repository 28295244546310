<template>
  <vs-prompt
      @accept="putPasswortToCookie"
      title="Passwort für Bank-Daten"
      @close="$emit('close')"
      acceptText="Passwort hinterlegen"
      cancelText="Abbrechen"
      :active.sync="activePrompt">
    <span>Passwort:</span>
    <vs-input v-model="password" type="password" class="mb-4"></vs-input>
  </vs-prompt>
</template>

<script>
import ApiService from "../../api";

export default {
  name: "MysqlSelectionPopup",
  components: {},
  props: [
    'active'
  ],
  data() {
    return {
      activePrompt: false,
      password: '',
    }
  },
  methods: {
    activeChange(value) {
      this.activePrompt = value;
    },
    putPasswortToCookie() {
      // default expire time: 1 day
      this.$cookies.set("mysql_bank_password",this.password,"14d")
      this.$emit('accept');
      this.$router.go(this.$router.currentRoute)
    }
  },
  watch: {
    active: 'activeChange'
  }
}
</script>