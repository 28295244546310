<template>
    <div class="billing-transfer-modal">
        <vs-popup class="billing-transfer-modal--popup" title="Neue Überweisung hinzufügen" @close="onClose"
                  :active="visible">
            <div v-show="dataTransfer">

                <div class="vx-row mb-2 p-4">
                    <div class="vx-col  w-full mb-0">

                        <vs-row class="mt-4">
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                                <label>Empfänger Name:</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                                <vs-input v-model="dataTransfer.recipient_name" placeholder="Empfänger" name="iban" class="mb-2 w-full" v-validate="'required'"/>
                                <span class="text-danger text-sm" v-if="errors.has('recipient_name')">Dieses Feld ist ein Pflichtfeld</span>
                            </vs-col>
                        </vs-row>

                        <vs-row class="mt-4">
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                                <label>Bankverbindung:</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                                <vs-row>
                                    <vs-col vs-w="6">
                                        <vs-input v-model="dataTransfer.iban" placeholder="IBAN" name="iban" class="mb-2 w-full"  data-vv-as="IBAN" v-validate="'required'" />
                                        <span class="text-danger text-sm" v-if="errors.has('iban')">Dieses Feld ist ein Pflichtfeld</span>
                                    </vs-col>
                                    <vs-col  vs-w="6" class="pl-4">
                                        <vs-input v-model="dataTransfer.bic" placeholder="BIC" name="bic" class="mb-2 w-full" v-validate="'required'"/>
                                        <span class="text-danger text-sm " v-if="errors.has('bic')">Dieses Feld ist ein Pflichtfeld</span>
                                    </vs-col>
                                </vs-row>
                            </vs-col>
                        </vs-row>

                      <vs-row class="mt-4">
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                          <label>Betrag:</label>
                        </vs-col>

                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                          <vs-row>
                            <vs-col vs-w="6">
                              <vs-input v-model="dataTransfer.sum" placeholder="Summe" v-validate="'required'"
                                        name="sum"
                                        class="mb-2 w-full"
                                        v-currency="{
                                              currency: 'EUR',
                                              locale: 'de',
                                              autoDecimalMode: false,
                                              decimalLength: 2,
                                              min: 0,
                                     }"
                              />
                              <span class="text-danger text-sm" v-if="errors.has('sum')">Dieses Feld ist ein Pflichtfeld</span>
                              <small class="documentprice-badge" v-if="document" >Hinterlegter Preis auf dem Beleg: {{ document.sum_brutto }} €</small>
                            </vs-col>
                            <vs-col  vs-w="6" class="pl-4">
                              <vs-input v-model="dataTransfer.due_from" placeholder="Überweisung vornehmen ab: (Datum)" name="due_from" class="mb-2 w-full" />
                              <span class="text-danger text-sm " v-if="errors.has('due_from')">Dieses Feld ist ein Pflichtfeld</span>
                            </vs-col>
                          </vs-row>
                        </vs-col>
                      </vs-row>

                      <vs-row class="mt-4">
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                          <label>Buchungstext:</label>
                        </vs-col>

                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                          <vs-row>
                            <vs-col vs-w="6">
                              <vs-textarea v-model="dataTransfer.reference_text" placeholder="Buchungstext" v-validate="'required'"
                                           name="reference_text" class="mb-2 w-full"/>
                              <span class="text-danger text-sm" v-if="errors.has('reference_text')">Dieses Feld ist ein Pflichtfeld</span>
                            </vs-col>
                            <vs-col vs-w="6" class="pl-4">
                              <vs-textarea v-model="dataTransfer.internal_notice" placeholder="Interne Notiz"
                                           name="internal_notice" class="mb-2 w-full"/>
                              <span class="text-danger text-sm " v-if="errors.has('internal_notice')">Dieses Feld ist ein Pflichtfeld</span>
                            </vs-col>
                          </vs-row>
                        </vs-col>
                      </vs-row>


                      <vs-row class="mt-4">
                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                          <label>Verweise:</label>
                        </vs-col>

                        <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                          <vs-row>
                            <vs-col vs-w="6">
                              <mg-select v-model="dataTransfer.category_id" :options="categories" name="category_id"
                                         placeholder="Zuordnung zum Kategorie "
                                         track-by="id" label="name" data-vv-as="Zuordnung zum Kategorie">
                              </mg-select>

                            </vs-col>
                            <vs-col vs-w="6" class="pl-4">
                             ...
                            </vs-col>
                          </vs-row>
                        </vs-col>
                      </vs-row>

                    </div>
                </div>



            </div>

            <div class="flex p-2 justify-end bg-gray-200 rounded-b">
                <vs-button color="danger" type="border" @click="onClose" class="mr-2">Abbrechen</vs-button>
                <vs-button @click="createData" :disabled="!formValid">Neue Überweisung hinzufügen</vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
    import ApiService from "../../api";
    import staticOptions from "../../mixins/static/options";
    import MgSelect from "../../components/mg-select/MgSelect";
    import * as qs from "qs";
    import moment from 'moment';
    import PriceHelper from "../../mixins/helper/price.helper";
    import {mapGetters} from "vuex";

    export default {
        name: "create-transfer-modal",
        components: {
            MgSelect
        },
        props: {
            active: {
                type: Boolean,
                default: false
            },
          document: {
            default: null
          }
        },
        data() {
            return {
                dataTransfer: {},
                categories: [],
                newData: {
                  "iban": null,
                  "bic": null,
                  "sum": null,
                  "recipient_name": null,
                  "reference_text": null,
                  "internal_notice": null,
                  "bank_transaction_id": null,
                  "client_id": null,
                  "order_id": null,
                  "category_id": null,
                  "due_from": null,
                  "sepa_xml_export_date": null
                },
            }
        },
        created() {


        },
        computed: {
            ...mapGetters(['userHasPermission', 'userId', 'user']),
            visible() {
                return this.active;
            },
            formValid() {
              return !this.errors.any();
            },
        },
        methods: {
            openModal() {

              if(!this.dataTransfer) {
                this.dataTransfer = JSON.parse(JSON.stringify(this.newData));
              }
              this.fetchData();

            },
            fetchData() {

                this.categoriesPromise()

                if (this.document) {
                  this.dataTransfer.iban = this.document.iban.replace(/\s+/g, '');
                  this.dataTransfer.bic = this.document.bic.replace(/\s+/g, '');
                  this.dataTransfer.sum = PriceHelper.floatPointToComma(this.document.sum_brutto.trim(this.document.sum_brutto.replace("€","")));
                  this.dataTransfer.recipient_name = this.document.client ? this.document.client.company_name : ""
                  this.dataTransfer.reference_text = this.document.notice
                  this.dataTransfer.internal_notice = null
                  this.dataTransfer.bank_transaction_id = this.document.bank_transaction_id
                  this.dataTransfer.client_id = this.document.client_id
                  this.dataTransfer.order_id = this.document.order_id
                  this.dataTransfer.category_id = null
                  this.dataTransfer.due_from = this.document.due_on
                  this.dataTransfer.sepa_xml_export_date = null
                }

            },
              categoriesPromise() {
                ApiService.get('categories', {
                  params: {
                    filter: [{
                      field: 'usable_on',
                      value: 'bank_transaction_transfer'
                    }],
                  },
                  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                      this.categories = response.data.result
                    }
                );
              },
            onClose() {
                this.$emit('update:active', false)
                this.$emit('close')
            },
           onSave() {
                this.$emit('update:active', false)
                this.$emit('saved')
            },
            fetchClients() {
                return ApiService.get(`clients`)
                    .then(response => {
                        if (response.status == 200) {
                            this.clients = response.data.result;
                        } else {
                            this.$vs.notify({
                                title: 'Fehler beim Kunden laden',
                                text: '',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        }
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehler beim Kunden laden',
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    });
            },
            fetchOffers(value) {
                let queryParams = {
                    filter: [
                        {
                            field: 'type.invoice_type',
                            expression: 'exact',
                            value: 'offer'
                        },
                        {
                            field: 'client_id',
                            expression: 'exact',
                            value: value.id
                        },
                        {
                            expression: 'group',
                            filter: [
                                {
                                    field: 'status',
                                    expression: 'exact',
                                    value: '16',
                                    operator: 'or',
                                },
                                {
                                    field: 'status',
                                    expression: 'exact',
                                    value: '18',
                                    operator: 'or',
                                }
                            ]
                        }
                    ]
                };

                return ApiService.get('orders', {
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.offers = response.data.result;
                })
            },
            createData() {

                this.$validator.validate().then(valid => {
                    if (valid) {

                      const payload = JSON.parse(JSON.stringify(this.dataTransfer));

                      payload.sum = payload.sum.replace("€","");
                      payload.sum = payload.sum.replace(/\s+/g, '');
                      payload.sum = PriceHelper.parseCommaFloat(payload.sum);

                        ApiService.post('bank-transaction-transfer', payload).then((response) => {

                          if (response.data.status == "success") {
                            this.$vs.loading.close();
                            this.$vs.notify({
                              title: 'Erfolgreich',
                              text: 'Die Überweisung wurde erfolgreich erstellt.',
                              iconPack: 'feather',
                              icon: 'icon-alert-circle',
                              color: 'success'
                            });


                            const payloadDocument = {
                              bank_transaction_transfer_id: response.data.result.id,
                            };

                            ApiService.put('bank-transactions/document/' + this.$route.params.fileId, payloadDocument)
                                .then((response) => {
                                  if (response.data.status === 'success') {
                                    this.onSave()
                                    return this.$vs.notify({
                                      title: 'Erfolgreich',
                                      text: 'Dokument erfolgreich bearbeitet',
                                      iconPack: 'feather',
                                      icon: 'icon-alert-circle',
                                      color: 'success'
                                    })
                                  }
                                }).catch((error) => {
                                  this.onSave()
                                  this.$vs.loading.close();
                                  this.$vs.notify({
                                    title: 'Fehler',
                                    text: error.message,
                                    iconPack: 'feather',
                                    icon: 'icon-alert-circle',
                                    color: 'danger'
                                  })
                            });



                          } else {
                            this.$vs.loading.close();
                            this.$vs.notify({
                              title: 'Fehler',
                              text: "Überweisung konnte nicht angelegt werden",
                              iconPack: 'feather',
                              icon: 'icon-alert-circle',
                              color: 'danger'
                            });
                          }

                        }).catch((error) => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: 'Fehler',
                                text: error.message,
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        });
                    } else {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: this.$validator.errors.all(),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    }
                })
            },
            customClientLabel(option) {
                return `${option.customer_number} - ${option.company_name}`;
            },
            customOfferLabel(option) {
                return `${option.invoice_number} - ${option.title} - ${option.order_date}`;
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },

        },
        watch: {
            active: function (val) {
                if (val === true) {
                    this.openModal();
                }
            },
        }
    }
</script>

<style lang="scss">
    .billing-transfer-modal--popup {
        .vs-popup {
            min-width: 700px;
            max-width: 100vw;
        }

        .vs-popup--content {
            padding: 5px 0 0 0;
            margin: 0;
            min-height: 60px;
            width: 100%;
        }
      .documentprice-badge {
        top: -8px;
        position: relative;
        font-size: 10px;
      }
    }
</style>
