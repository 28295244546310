<template>
    <multiselect
            class="mg-select"
            v-model="selectValue"
            :options="options"
            :name="name"
            :selectLabel="selectLabel"
            :selectGroupLabel="selectGroupLabel"
            :deselectLabel="deselectLabel"
            :deselectGroupLabel="deselectGroupLabel"
            :showLabels="showLabels"
            :limit="limit"
            :maxHeight="maxHeight"
            :limitText="limitText"
            :loading="loading"
            :disabled="disabled"
            :openDirection="openDirection"
            :oOptions="oOptions"
            :showNoResults="showNoResults"
            :tabindex="tabindex"
            :trackBy="trackBy"
            :label="label"
            :searchable="searchable"
            :clearOnSelect="clearOnSelect"
            :hideSelected="hideSelected"
            :placeholder="placeholder"
            :allowEmpty="allowEmpty"
            :resetAfter="resetAfter"
            :closeOnSelect="closeOnSelect"
            :customLabel="customLabel"
            :taggable="taggable"
            :tagPlaceholder="tagPlaceholder"
            :tagPosition="tagPosition"
            :max="max"
            :optionsLimit="optionsLimit"
            :groupValues="groupValues"
            :groupLabel="groupLabel"
            :groupSelect="groupSelect"
            :blockKeys="blockKeys"
            :preserveSearch="preserveSearch"
            :preselectFirst="preselectFirst"
            @input="handleInput"
            @select="(selectedOption, id) => $emit('select', selectedOption, id)"
            @remove="(removedOption, id) => $emit('remove', removedOption, id)"
            @search-change="(searchQuery, id) => $emit('search-change', searchQuery, id)"
            @tag="(searchQuery, id) => $emit('tag', searchQuery, id)"
            @open="	(id) => $emit('open', id)"
            @close="(val, id) => $emit('open', val, id)"
    >
        <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData">
            <slot :name="name" v-bind="slotData"/>
        </template>
    </multiselect>
</template>

<script>
    import Multiselect from 'vue-multiselect'

    function isEmpty(opt) {
        if (opt === 0) return false
        if (Array.isArray(opt) && opt.length === 0) return true
        return !opt
    }

    export default {
        name: "MgSelect",
        components: {Multiselect},
        props: {
            value: {
                type: null,
                default() {
                    return []
                }
            },
            options: {
                type: Array,
                default() {
                    return []
                }
            },
            trackBy: {
                type: String
            },
            label: {
                type: String
            },
            name: {
                type: String,
                default: ''
            },
            selectLabel: {
                type: String,
                default: 'Press enter to select'
            },
            selectGroupLabel: {
                type: String,
                default: 'Press enter to select group'
            },
            selectedLabel: {
                type: String,
                default: 'Selected'
            },
            deselectLabel: {
                type: String,
                default: 'Press enter to remove'
            },
            deselectGroupLabel: {
                type: String,
                default: 'Press enter to deselect group'
            },
            showLabels: {
                type: Boolean,
                default: true
            },
            limit: {
                type: Number,
                default: 99999
            },
            maxHeight: {
                type: Number,
                default: 300
            },
            limitText: {
                type: Function,
                default: count => `and ${count} more`
            },
            loading: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            openDirection: {
                type: String,
                default: ''
            },
            oOptions: {
                type: Boolean,
                default: true
            },
            showNoResults: {
                type: Boolean,
                default: true
            },
            tabindex: {
                type: Number,
                default: 0
            },
            searchable: {
                type: Boolean,
                default: true
            },
            clearOnSelect: {
                type: Boolean,
                default: true
            },

            hideSelected: {
                type: Boolean,
                default: false
            },

            placeholder: {
                type: String,
                default: 'Select option'
            },

            allowEmpty: {
                type: Boolean,
                default: true
            },

            resetAfter: {
                type: Boolean,
                default: false
            },

            closeOnSelect: {
                type: Boolean,
                default: true
            },
            customLabel: {
                type: Function,
                default(option, label) {
                    if (isEmpty(option)) return ''
                    return label ? option[label] : option
                }
            },
            taggable: {
                type: Boolean,
                default: false
            },
            tagPlaceholder: {
                type: String,
                default: 'Press enter to create a tag'
            },
            tagPosition: {
                type: String,
                default: 'top'
            },
            max: {
                type: [Number, Boolean],
                default: false
            },
            id: {
                default: null
            },
            optionsLimit: {
                type: Number,
                default: 1000
            },
            groupValues: {
                type: String
            },
            groupLabel: {
                type: String
            },
            groupSelect: {
                type: Boolean,
                default: false
            },
            blockKeys: {
                type: Array,
                default() {
                    return []
                }
            },
            preserveSearch: {
                type: Boolean,
                default: false
            },
            preselectFirst: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                selectValue: null,
                initialValues: []
            }
        },
        created() {
            this.initialValues = this.value;

            this.handlePreSelection(this.initialValues);
        },
        methods: {
            handlePreSelection(values) {
                if (values) {
                    if (values instanceof Array) {
                        this.selectValue = this.options.filter(x => values.includes(x[this.trackBy]));
                    } else {
                        this.selectValue = this.options.filter(x => values === x[this.trackBy]);
                    }
                    return;
                }

                this.selectValue = null;
            },
            handleInput(value, id) {
                if (value) {
                    value = value[this.trackBy]
                }
                this.$emit('input', value, id);
            },
            hasSlot(name = 'default') {
                return !!this.$slots[name] || !!this.$scopedSlots[name];
            }
        },
        watch: {
            initialValues: {
                handler(values) {
                    this.handlePreSelection(values);
                }
            },
            value: {
                handler(values) {
                    this.handlePreSelection(values);
                }
            },
            options: {
                handler() {
                    this.handlePreSelection(this.value);
                }
            }
        }
    }
</script>

<style scoped>

</style>
