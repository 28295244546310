const staticOptions = {
    units: [
        {value: 1, label: 'Stk.', hour_unit: false, multiplicator: 1, labelShort:"Stk."},
        {value: 2, label: 'Monat', hour_unit: false, multiplicator: 1, labelShort:"Mon."},
        {value: 3, label: 'Jahr', hour_unit: false, multiplicator: 1, labelShort:"Jahr"},
        {value: 4, label: 'Stunde/n', hour_unit: true, multiplicator: 1,labelShort:"Std."},
        {value: 5, label: 'Manntag/e', hour_unit: true, multiplicator: 8,labelShort:"MT"}
    ],
    tax: [
        {value: 0, label: '0%'},
        {value: 7, label: '7%'},
        {value: 19, label: '19%'},
    ],
    salutations: [
        {value: 'mr', label: 'Herr', isSelected: true},
        {value: 'ms', label: 'Frau', isSelected: false},
        {value: 'divers', label: 'Diverse', isSelected: false},
        {value: '-', label: '-', isSelected: false},
    ],
    orderStatus: [
        {value: 1, label: 'Offen'},
        {value: 2, label: 'Storniert'},
        {value: 3, label: 'Abgeschlossen'},
    ],
    clientTypes: [
        {value: 'client', label: 'Kunde'},
        {value: 'lead', label: 'Lead'},
        {value: 'supplier', label: 'Lieferant'},
        {value: 'partner', label: 'Partner'},
        {value: 'freelancer', label: 'Freelancer'},
    ],
    employeeTypes: [
        {value: 'employee', label: 'Interner Mitarbeiter'},
        {value: 'extern', label: 'Externer Mitarbeiter'},
        {value: 'freelance', label: 'Freelancer'},
        {value: 'supplier', label: 'Lieferant Mitarbeiter'},
        {value: 'other', label: 'Sonstiger Zugang'},
    ],
    countries: [
        {value: 1, label: 'Deutschland'},
        {value: 2, label: 'Schweiz'},
        {value: 3, label: 'Österreich'},
        {value: 4, label: 'UK'},
        {value: 5, label: 'Griechenland'},
        {value: 6, label: 'USA'},
        {value: 7, label: 'Polen'},
        {value: 8, label: 'Frankreich'},
        {value: 9, label: 'Italien'},
        {value: 11, label: 'Niederlande'},
        {value: 12, label: 'Schweden'},
        {value: 13, label: 'Norwegen'},
        {value: 14, label: 'Belgien'},
        {value: 15, label: 'Dänemark'},
        {value: 16, label: 'Finnland'},
        {value: 17, label: 'Rumänien'},
        {value: 18, label: 'Kroatien'},
        {value: 19, label: 'Tschechien'},
        {value: 20, label: 'Malta'},
        {value: 21, label: 'Australien'},
        {value: 22, label: 'Japan'},
        {value: 23, label: 'China'},
        {value: 24, label: 'Indien'},
        {value: 25, label: 'Kanada'},
        {value: 26, label: 'Russland'},
        {value: 27, label: 'Spanien'},
        {value: 28, label: 'Türkei'},
        {value: 29, label: 'Irland'},
        {value: 30, label: 'Kosovo'},
        {value: 31, label: 'Israel'},
        {value: 32, label: 'Estland'},
    ],
    months:[
        {
            "id": "01",
            "name": "Januar"
        },
        {
            "id": "02",
            "name": "Februar"
        },
        {
            "id": "03",
            "name": "März"
        },
        {
            "id": "04",
            "name": "April"
        },
        {
            "id": "05",
            "name": "Mai"
        },
        {
            "id": "06",
            "name": "Juni"
        },
        {
            "id": "07",
            "name": "Juli"
        },
        {
            "id": "08",
            "name": "August"
        },
        {
            "id": "09",
            "name": "September"
        },
        {
            "id": "10",
            "name": "Oktober"
        },
        {
            "id": "11",
            "name": "November"
        },
        {
            "id": "12",
            "name": "Dezember"
        },
    ],
    licenceIncludePlan: ['Starter', 'Advanced', 'Unlimited','old'],


};

export default staticOptions;
